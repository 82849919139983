import React from 'react'
import { PageProps } from 'gatsby'

import Layout from 'src/layouts'
import Giveaways from 'src/components/Giveaways'

const GiveawaysPage: React.FC<PageProps> = ({ location }) => (
  <Layout location={location}>
    <Giveaways />
  </Layout>
)

export default GiveawaysPage
